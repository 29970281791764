import { useCallback } from 'react'
import { Button, Card, Page, Text } from '@shopify/polaris'
import { XIcon as DisconnectIcon, ComposeIcon as ReviewIcon } from '@shopify/polaris-icons'
import { useAppBridge } from '@shopify/app-bridge-react'
import { useSearchParams } from 'react-router-dom'

import { FasletIcon, BillingBlock, Gap, Row, Spacer } from '../../components'
import { AppBlockInstallation } from './app-block-installation'
import { useFasletService } from '../../utils/faslet-service'
import { PricePlan, Status } from '../../types'

interface LoggedInProps {
  i18n: any
  localShopId: string | undefined
  status: Status
  pricePlan: PricePlan
  loading: boolean
  setLoading: (value: boolean) => void
  setLocalShopId: (value: string | undefined) => void
  queryStatus: (shopId?: string) => Promise<void>
  activatePricePlan: () => void
}

export function LoggedIn({
  i18n,
  localShopId,
  status,
  pricePlan,
  loading,
  setLoading,
  setLocalShopId,
  queryStatus,
  activatePricePlan,
}: LoggedInProps) {
  const shopify = useAppBridge()
  const { setShopId, deletePixel, installPixel, syncProducts } = useFasletService()

  const [searchParams] = useSearchParams()
  const shop = searchParams.get('shop') ?? undefined

  const disconnectShopId = useCallback(async () => {
    setLoading(true)
    setLocalShopId(undefined)
    await deletePixel()
    await setShopId()
    await queryStatus()
    setLoading(false)
  }, [localShopId, setShopId, queryStatus, setLocalShopId, deletePixel])

  const onInstallPixel = useCallback(async () => {
    setLoading(true)
    const { error } = await installPixel(localShopId)
    if (error) {
      shopify.toast.show(i18n.translate('orderTrackingErrorInstall'), { duration: 5000 })
    }
    await queryStatus(localShopId)
    setLoading(false)
  }, [installPixel, setLoading, localShopId, shopify.toast, i18n.translate])

  const onDeletePixel = useCallback(async () => {
    setLoading(true)
    const { error } = await deletePixel()
    if (error) {
      shopify.toast.show(i18n.translate('orderTrackingErrorUninstall'), { duration: 5000 })
    }
    await queryStatus(localShopId)
    setLoading(false)
  }, [installPixel, setLoading, localShopId, shopify.toast, i18n.translate])

  const onSyncProducts = useCallback(async () => {
    setLoading(true)
    const { error } = await syncProducts()
    setLoading(false)
  }, [syncProducts, shopify.toast, i18n.translate])

  return (
    <Page
      title={`${i18n.translate('title')} - ${localShopId}`}
      secondaryActions={[
        {
          content: i18n.translate('home.openPartnerPortal'),
          onAction: () => window.open('https://portal.faslet.net', '_blank'),
          icon: FasletIcon,
        },
        {
          content: i18n.translate('home.disconnectStore'),
          onAction: disconnectShopId,
          destructive: true,
          icon: DisconnectIcon,
        },
      ]}
    >
      <Card>
        <Row>
          <Text variant="headingMd" as="h4">
            {i18n.translate('home.loggedInHeader')}
          </Text>
        </Row>
        <Gap />
        {status.appBlocksSupported ? (
          <AppBlockInstallation shop={shop} shopId={localShopId} status={status} pricePlan={pricePlan} />
        ) : (
          <Text variant="bodyMd" as="p" tone="caution">
            {i18n.translate('home.appBlocksNotSupported')}
          </Text>
        )}
        <Gap />
        <Row>
          <Text variant="bodyMd" as="h4">
            {i18n.translate('home.otPixel')}
          </Text>
          <Spacer />
          {status.orderTrackingPixel ? (
            <Button onClick={onDeletePixel} loading={loading}>
              {i18n.translate('home.uninstallOT')}
            </Button>
          ) : (
            <Button onClick={onInstallPixel} loading={loading}>
              {i18n.translate('home.installOT')}
            </Button>
          )}
        </Row>
        <Gap />
        <Row>
          <Text variant="bodyMd" as="h4">
            {i18n.translate('home.review')}
          </Text>
          <Spacer />
          <Button
            onClick={() => window.open('https://apps.shopify.com/faslet-size-me-up/reviews', '_blank')}
            icon={ReviewIcon}
          >
            {i18n.translate('home.review1')}
          </Button>
        </Row>
        <Gap />
        {(!status.isDevelopmentStore || localShopId === 'faslet-demo') && !!pricePlan?.name && (
          <>
            <BillingBlock pricePlan={pricePlan} activatePricePlan={activatePricePlan} />
            <Gap />
          </>
        )}
      </Card>
    </Page>
  )
}
