import React from 'react'

import { AppProvider } from '@shopify/polaris'
import { I18nManager, I18nContext } from '@shopify/react-i18n'
import { BrowserRouter, Route, Routes, useSearchParams } from 'react-router-dom'

import '@shopify/polaris/build/esm/styles.css'

import './css/faslet.css'
import './css/animations.css'
import { FasletApp } from './FasletApp'
import { usePolarisI18n } from './utils/i18n'

function I18nWrapper() {
  const [searchParams] = useSearchParams()
  const locale = searchParams.get('locale') ?? undefined

  const i18nManager = new I18nManager({
    locale: locale ?? 'en',
    fallbackLocale: 'en',
    onError(error) {
      console.error(error)
    },
  })

  return (
    <I18nContext.Provider value={i18nManager}>
      <AppWrapper />
    </I18nContext.Provider>
  )
}

function App() {
  return (
    <BrowserRouter>
      <I18nWrapper />
    </BrowserRouter>
  )
}

function AppWrapper() {
  const [i18n] = usePolarisI18n()

  return (
    <AppProvider i18n={i18n.translations}>
      <Routes>
        <Route path="*" element={<FasletApp />} />
      </Routes>
    </AppProvider>
  )
}

export default App
