import { useCallback, useMemo, useState } from 'react'
import { Button, Card, Link, Page, Text, TextField, Box, Checkbox, Select } from '@shopify/polaris'
import { ConnectIcon as ConnectIcon, CheckIcon as ConfirmIcon, XIcon as CancelIcon } from '@shopify/polaris-icons'
import { Modal, TitleBar } from '@shopify/app-bridge-react'
import { useAppBridge } from '@shopify/app-bridge-react'

import { Gap, Row, Spacer, FasletIcon } from '../../components'
import { useFasletService } from '../../utils/faslet-service'

interface NotLoggedInProps {
  i18n: any
  loading: boolean
  localShopId: string | undefined
  setLocalShopId: (value: string) => void
  setLoading: (value: boolean) => void
  queryStatus: (shopId?: string) => Promise<void>
}

export function NotLoggedIn({ i18n, loading, localShopId, setLocalShopId, setLoading, queryStatus }: NotLoggedInProps) {
  const shopify = useAppBridge()
  const { setShopId, installPixel, getShopInfo, signup, syncProducts } = useFasletService()
  const annualRevenueOptions = [
    { label: '€0 - €1,000,000', value: '1000000' },
    { label: '€1,000,000 - €5,000,000', value: '5000000' },
    { label: '€5,000,000 - €10,000,000', value: '10000000' },
    { label: '€10,000,000 - €50,000,000', value: '50000000' },
    { label: '€50,000,000 - €500,000,000', value: '500000000' },
    { label: '€500,000,000+', value: '1000000000' },
  ]

  const [storeName, setStoreName] = useState('')
  const [storeUrl, setStoreUrl] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [annualRevenue, setAnnualRevenue] = useState(annualRevenueOptions[0].value)
  const [couponCode, setCouponCode] = useState('')
  const [acceptedTerms, setAcceptedTerms] = useState(false)

  const showConnectModal = useCallback(() => {
    shopify.modal.show('connect-modal')
  }, [shopify.modal])

  const hideConnectModal = useCallback(() => {
    shopify.modal.hide('connect-modal')
  }, [shopify.modal])

  const saveShopId = useCallback(
    async (shopId?: string) => {
      setLoading(true)
      await setShopId(shopId ?? localShopId)
      await queryStatus(shopId)
      const { error } = await installPixel(shopId)
      if (error) {
        shopify.toast.show(i18n.translate('orderTrackingErrorInstall'), { duration: 5000 })
      }
      await queryStatus(shopId)
      setLoading(false)
      hideConnectModal()
    },
    [setShopId, localShopId, queryStatus, hideConnectModal, shopify.toast, i18n.translate]
  )

  const canSignUp = useMemo(
    () => storeName && storeUrl && firstName && lastName && email && phone && annualRevenue && acceptedTerms,
    [storeName, storeUrl, firstName, lastName, email, phone, annualRevenue, acceptedTerms]
  )

  const showSignUpFlow = useCallback(async () => {
    setLoading(true)
    const result = await getShopInfo()

    if (result.error) {
      shopify.toast.show(i18n.translate('shopInfoError'), { duration: 5000 })
      setLoading(false)
      return
    }

    setEmail(result.result.email)
    setStoreName(result.result.name)
    setStoreUrl(result.result.url)
    setLoading(false)
    shopify.modal.show('signup-modal')
  }, [shopify.modal])

  const hideSignUpFlow = useCallback(() => {
    shopify.modal.hide('signup-modal')
  }, [shopify.modal])

  const handleSignUp = useCallback(async () => {
    if (!storeName || !storeUrl || !firstName || !lastName || !email || !phone || !annualRevenue || !acceptedTerms) {
      shopify.toast.show(i18n.translate('pleaseFillAllRequired'), { duration: 5000 })
      return
    }
    try {
      setLoading(true)
      const response = await signup(storeName, email, storeUrl, firstName, lastName, phone, annualRevenue, couponCode)
      if (response.error) throw new Error()

      setLocalShopId(response.result.shopId)
      await saveShopId(response.result.shopId)
      await syncProducts()

      shopify.toast.show(i18n.translate('signupSuccess'), { duration: 5000 })
      hideSignUpFlow()
    } catch (e) {
      shopify.toast.show(i18n.translate('signupError'), { duration: 5000 })
    } finally {
      setLoading(false)
    }
  }, [
    storeName,
    storeUrl,
    firstName,
    lastName,
    email,
    phone,
    annualRevenue,
    acceptedTerms,
    couponCode,
    hideSignUpFlow,
    shopify.toast,
    i18n,
    signup,
    saveShopId,
    setLocalShopId,
    syncProducts,
  ])

  const origin = window.location.origin
  const banner = `${origin.replace('floss', 'shopify-app')}/banner.jpg`

  return (
    <Page title={i18n.translate('welcome')}>
      <Card>
        <Text variant="headingMd" as="h4">
          {i18n.translate('home.welcome')}
        </Text>
        <Gap />
        <Text variant="bodyMd" as="p">
          {i18n.translate('home.introText1')}{' '}
          <Link url="mailto:support@faslet.me" target="_blank">
            {i18n.translate('home.introText2')}
          </Link>
          .
          <br />
          {i18n.translate('home.introText3')}
        </Text>
        <Gap size={4} />
        <Text variant="bodyMd" as="h4" alignment="center">
          {i18n.translate('home.pricingStarts', { price: <strong>$79,00</strong> })}
        </Text>
        <Gap size={4} />
        <Row>
          <Spacer />
          <Button variant="primary" icon={ConnectIcon} size="large" onClick={showConnectModal} loading={loading}>
            {i18n.translate('home.connectStore')}
          </Button>
          <Gap />
          <Button size="large" icon={FasletIcon} onClick={showSignUpFlow} loading={loading}>
            {i18n.translate('home.signUp')}
          </Button>
          <Spacer />
        </Row>
      </Card>
      <Modal id="connect-modal">
        <TitleBar title={i18n.translate('home.connectStore')} />
        <img src={banner} alt="Signup header" style={{ width: '100%', marginBottom: '1rem' }} />
        <Box padding="400">
          <Gap size={1} />
          <Text variant="headingMd" as="p">
            {i18n.translate('home.shopId')}
          </Text>
          <Gap />
          <TextField
            placeholder={i18n.translate('home.enterShopId')}
            value={localShopId}
            disabled={loading}
            onChange={setLocalShopId}
            autoComplete="off"
            label=""
          />
          <Gap />
          <Row>
            <Spacer />
            <Button icon={CancelIcon} onClick={hideConnectModal} loading={loading} disabled={loading}>
              {i18n.translate('cancel')}
            </Button>
            <Gap size={1} />
            <Button
              icon={ConfirmIcon}
              variant="primary"
              onClick={() => saveShopId()}
              loading={loading}
              disabled={!localShopId || loading}
            >
              {i18n.translate('save')}
            </Button>
          </Row>
        </Box>
      </Modal>
      <Modal id="signup-modal">
        <TitleBar title={i18n.translate('home.signUp')} />
        <img src={banner} alt="Signup header" style={{ width: '100%', marginBottom: '1rem' }} />
        <Box padding="400">
          <Gap size={1} />
          <Text variant="headingMd" as="p">
            {i18n.translate('home.enterAllFields')}
          </Text>
          <Gap size={2} />
          <TextField
            label={i18n.translate('home.storeNameLabel')}
            value={storeName}
            onChange={setStoreName}
            autoComplete="off"
            requiredIndicator
          />
          <Gap size={2} />
          <TextField
            label={i18n.translate('home.storeUrlLabel')}
            value={storeUrl}
            onChange={setStoreUrl}
            autoComplete="off"
            requiredIndicator
          />
          <Gap size={2} />
          <Row>
            <TextField
              label={i18n.translate('home.firstNameLabel')}
              value={firstName}
              onChange={setFirstName}
              autoComplete="on"
              requiredIndicator
            />
            <Gap size={2} />
            <TextField
              label={i18n.translate('home.lastNameLabel')}
              value={lastName}
              onChange={setLastName}
              autoComplete="on"
              requiredIndicator
            />
          </Row>
          <Gap size={2} />
          <TextField
            label={i18n.translate('home.emailLabel')}
            value={email}
            onChange={setEmail}
            autoComplete="on"
            requiredIndicator
          />
          <Gap size={2} />
          <TextField
            label={i18n.translate('home.phoneLabel')}
            value={phone}
            onChange={setPhone}
            autoComplete="on"
            requiredIndicator
          />
          <Gap size={2} />
          <Select
            label={i18n.translate('home.annualRevenueLabel')}
            options={annualRevenueOptions}
            value={annualRevenue}
            onChange={setAnnualRevenue}
            requiredIndicator
          />
          <Gap size={2} />
          <Text variant="bodySm" tone="subdued" as="p">
            {i18n.translate('home.couponBlurb')}
          </Text>
          <Gap size={2} />
          <TextField
            label={i18n.translate('home.couponCodeLabel')}
            value={couponCode}
            onChange={setCouponCode}
            autoComplete="off"
          />
          <Gap size={2} />
          <Checkbox
            label={
              <Link url="https://site.faslet.me/terms-of-services" target="_blank">
                Accept Terms
              </Link>
            }
            checked={acceptedTerms}
            onChange={setAcceptedTerms}
          />
          <Gap size={2} />
          <Text variant="bodySm" tone="subdued" as="p">
            {i18n.translate('home.privacyBlurb')}{' '}
            <Link url="https://site.faslet.me/privacy-policy" target="_blank">
              {i18n.translate('home.privacyPolicyLink')}
            </Link>
            .
          </Text>
          <Gap size={4} />
          <Row>
            <Spacer />
            <Button icon={CancelIcon} onClick={hideSignUpFlow} disabled={loading}>
              {i18n.translate('cancel')}
            </Button>
            <Gap size={1} />
            <Button icon={ConfirmIcon} variant="primary" onClick={handleSignUp} disabled={!canSignUp || loading}>
              {i18n.translate('save')}
            </Button>
          </Row>
        </Box>
      </Modal>
    </Page>
  )
}
