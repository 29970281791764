import { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { PricePlan, Status } from '../../types'
import { useAppBridge } from '@shopify/app-bridge-react'
import { useFasletService } from 'utils/faslet-service'
import { useAppI18n } from '../../utils/i18n'
import { NotLoggedIn } from './not-logged-in'
import { LoggedIn } from './logged-in'

export function Home() {
  const [i18n] = useAppI18n()

  const shopify = useAppBridge()
  const [searchParams] = useSearchParams()
  const shop = searchParams.get('shop') ?? undefined

  const [localShopId, setLocalShopId] = useState<string | undefined>()
  const [validShopId, setValidShopId] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)
  const [pricePlan, setPricePlan] = useState<PricePlan>({})
  const [status, setStatus] = useState<Status>({})

  const { getBillingInfo, getShopId, getStatus, authenticate } = useFasletService()

  const activatePricePlan = useCallback(async () => {
    window.open(pricePlan.confirmationUrl, '_top')
  }, [pricePlan])

  const queryPricePlan = useCallback(
    async (shopId?: string) => {
      const { error, result } = await getBillingInfo(shopId ?? '')
      if (!error) {
        setPricePlan(result)
      } else {
        setPricePlan({})
      }
    },
    [getBillingInfo, setPricePlan]
  )

  const queryStatus = useCallback(
    async (shopId?: string) => {
      if (!shop) {
        shopify.toast.show('Shop not found', { duration: 5000 })
        return
      }

      const { error, result } = await getShopId()

      if (!error) {
        setLocalShopId(result.shopId)
        setValidShopId(true)
        await queryPricePlan(result.shopId)
      } else if (shopId) {
        shopify.toast.show('Shop not found', { duration: 5000 })
        setValidShopId(false)
      } else {
        setValidShopId(false)
      }

      const { error: statusError, result: statusResult } = await getStatus()

      if (!statusError) {
        setStatus(statusResult)
      }
    },
    [shop, getStatus, queryPricePlan, getShopId, setValidShopId, setLocalShopId, shopify.toast]
  )

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      await authenticate()
      await queryStatus()
      setLoading(false)
    })()
  }, [shop, queryStatus, authenticate])

  if (!validShopId) {
    return (
      <NotLoggedIn
        i18n={i18n}
        loading={loading}
        localShopId={localShopId}
        setLocalShopId={setLocalShopId}
        setLoading={setLoading}
        queryStatus={queryStatus}
      />
    )
  }

  return (
    <LoggedIn
      i18n={i18n}
      localShopId={localShopId}
      status={status}
      pricePlan={pricePlan}
      loading={loading}
      setLoading={setLoading}
      setLocalShopId={setLocalShopId}
      queryStatus={queryStatus}
      activatePricePlan={activatePricePlan}
    />
  )
}
