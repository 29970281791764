import { useI18n } from '@shopify/react-i18n'

import enPolaris from '@shopify/polaris/locales/en.json'
import dePolaris from '@shopify/polaris/locales/de.json'
import nlPolaris from '@shopify/polaris/locales/nl.json'

import enApp from '../translations/en.json'
import deApp from '../translations/de.json'
import nlApp from '../translations/nl.json'

const SUPPORTED_LOCALES = ['en', 'de', 'nl']

export function usePolarisI18n() {
  return useI18n({
    id: 'Polaris',
    fallback: enPolaris,
    translations(locale: string) {
      // Return early if locale not supported
      if (!SUPPORTED_LOCALES.some(supported => locale.startsWith(supported))) {
        return enPolaris
      }

      return import(
        /* webpackChunkName: "Polaris-i18n", webpackMode: "lazy-once" */ `@shopify/polaris/locales/${locale}.json`
      ).then(dictionary => dictionary && dictionary.default)
    },
  })
}

export function useAppI18n() {
  return useI18n({
    id: 'home',
    fallback: enApp,
    translations(locale) {
      if (locale.startsWith('de')) {
        return deApp
      }
      if (locale.startsWith('nl')) {
        return nlApp
      }
      return enApp
    },
  })
}
